import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { GET_CATALOG_MODELS, GET_CATALOG_BRANDS, GET_USER_AUTH } from '../../queries';
import { ListaCatalogoComponent, ListaCatalogoLoading } from '../../components/catalogo/lista/ListaCatalogo.component';
import Pagination from '../../components/common/Pagination.component';
import HeaderCatalogo from '../../components/catalogo/lista/HeaderCatalogo.component';
import { isBrowser } from '../../utils/ssr.utilis';
import { insertParam } from '../../api/navigation';
import { checkIfWasLoggedIn } from '../../utils/hooks/useIsLoggedUser';
import { isResellerUser } from '../../api/authorization';

const useStyles = makeStyles(theme => ({
  pagination: {
    marginTop: '1.688rem',
    display: 'flex',
    justifyContent: 'center'
  },

}));

const ListaCatalogoContainer = ({
  defaultPage = 1,
  filters = {},
  onDeleteFilter = () => { },
  onShowFilters,
  onUpdateFilter,
  setWhereClause,
  loading,
  error,
  data,
  sort,
  onShowOrder,
  orderOptions,
  handleChangeSort,
  labelSort
}) => {
  const {
    precioMin: precioDesde,
    precioMax: precioHasta,
    yearMin: yearDesde,
    yearMax: yearHasta,
    kmMin: kilometersDesde,
    kmMax: kilometersHasta,
    marca: brandId,
    modelo: modelId,
    segmento,
    transmision,
    combustible,
    condicion
  } = filters;
  const [page, setPage] = React.useState(parseInt(defaultPage));
  const [display, setDisplay] = React.useState('grid');

  const classes = useStyles();

  const { data: authData } = useQuery(GET_USER_AUTH, { fetchPolicy: 'network-only' });
  const { data: brandData } = useQuery(GET_CATALOG_BRANDS);
  const { data: modelData } = useQuery(GET_CATALOG_MODELS, {
    variables: { brandId: filters.marca }
  });

  const isLoggedIn = (authData && authData.currentUser !== null) || false;
  const isReseller = isLoggedIn && isResellerUser(authData?.currentUser?.profile?.role_name);

  useEffect(() => {
    let newWhere = {
      precio: { desde: parseInt(precioDesde), hasta: parseInt(precioHasta) },
      year: { desde: parseInt(yearDesde), hasta: parseInt(yearHasta) },
      kilometersRange: { desde: parseInt(kilometersDesde), hasta: parseInt(kilometersHasta) },
      segmento,
      transmision,
      combustible,
      condicion
    };
    if (brandData && brandId) {
      const selectedBrandId = brandId.split(',');
      const brand = brandData.catalogBrands.find(brand =>
        selectedBrandId.every(id =>
          brand.id.includes(id)
        )
      );
      if (brand) {
        newWhere = {
          ...newWhere,
          marca: brand.name
        };
      }

    }
    if (modelData && modelId) {
      const selectedModelId = modelId.split(',');
      const model = modelData.catalogModels.find(model =>
        selectedModelId.every(id =>
          model.id.includes(id)
        )
      );
      if (model) {
        newWhere = {
          ...newWhere,
          modelo: model.name
        };
      }
    }
    setWhereClause(newWhere);
    setPage(defaultPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandData, modelData, filters]);

  const cars = data?.cars?.results || [];
  const pagination = data?.cars?.pagination || {};
  const marcas = brandData?.catalogBrands || [];
  const modelos = modelData?.catalogModels || [];
  const total = pagination.total;
  function handleDisplay(newDisplay) {
    setDisplay(newDisplay);
  }

  const handlePaginationOnChange = (_, nextPage) => {
    if (isBrowser) {
      window.scrollTo(0, 0);
      insertParam('page', nextPage);
    }
    setPage(nextPage);
  };

  const handleUpdateFilter = (key, value) => {
    onUpdateFilter(key, value);
  };

  if (error) return <Grid container>{`Error: ${error.message}`}</Grid>;
  return (
    <Grid container direction="column" className={classes.root}>
      {loading ?
        (<ListaCatalogoLoading />) :
        (
          <Grid item style={{ width: '100%' }}>
            {/* El 100% es un parche temporal, todo parche temporal es para siempre */}
            <HeaderCatalogo
              filters={filters}
              marcas={marcas}
              modelos={modelos}
              total={total}
              onDeleteFilter={onDeleteFilter}
              onShowFilters={onShowFilters}
              onShowOrder={onShowOrder}
              onHandleDisplay={handleDisplay}
              onUpdateFilter={handleUpdateFilter}
              display={display}
              orderOptions={orderOptions}
              sort={sort}
              handleChangeSort={handleChangeSort}
              labelSort={labelSort}
            />
            <ListaCatalogoComponent data={cars} total={total} display={display} isLoggedIn={isReseller} />
          </Grid>
        )
      }
      <Grid item className={classes.pagination}>
        <Pagination
          data-test-id="pagination"
          count={pagination.pages}
          page={parseInt(page)}
          onChange={handlePaginationOnChange} />
      </Grid>
    </Grid>
  );

};

ListaCatalogoContainer.propTypes = {
  filters: PropTypes.object,
  onDeleteFilter: PropTypes.func,
  onShowFilters: PropTypes.func,
};

export default ListaCatalogoContainer;
