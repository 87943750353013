import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, IconButton, Box, Grid } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import MaskedTextField from '../common/MaskedTextField.component';
import RadioButtonsGroup from '../common/RadioButtonsGroup.component';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textInput: {
    height: '1.5625rem',
    fontSize: theme.typography.size.small,
  },
  iconButtonContainer: {
    color: theme.palette.grayScale.g500,
    border: `1px solid ${theme.palette.grayScale.g500}`,
    padding: '0.3rem',
    width: '35px',
    height: '35px',
    '&:hover': {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    }
  },
  icon: {
    width: '12px',
    height: '12px',
  },
  clearButton: {
    fontSize: '1rem',
  },
  inputAdornedEnd: {
    paddingRight: '5px',
  },
  boxLabel: {
    '& > span': {
      fontSize: '0.875rem'
    }
  },
  inputContainer: {
    margin: '0 4px',
    '&>div': {
      marginTop: 0
    }
  },
  borderContainer: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#D1D1D1',
        height: '35px'
      }
    }
  },
  errorContainer: {
    paddingLeft: '1.25rem',
    paddingTop: '0.5rem',
    fontSize: theme.typography.size.small,
    color: '#C53814',
    padding: '5px',
    alignItems: 'flex-start',
  },
  errorContainerDesktop: {
    paddingTop: '0.5rem',
    fontSize: theme.typography.size.small,
    color: '#C53814',
    padding: '5px',
    alignItems: 'flex-start',
  }
}));

const mask = new Array(8).fill(/\d/);

const kilometrosOptions = [
  { label: '0 km', value: { kmMin: '0', kmMax: '0' } },
  { label: '0 a 30.000 km', value: { kmMin: '0', kmMax: '30000' } },
  { label: '30.000 a 55.000 km', value: { kmMin: '30000', kmMax: '55000' } },
  { label: '55.000 a 80.000 km', value: { kmMin: '55000', kmMax: '80000' } },
  { label: '80.000 a 100.000 km', value: { kmMin: '80000', kmMax: '100000' } },
  { label: '100.000 km o más', value: { kmMin: '100000', kmMax: '99999999' } },
];


const INVALID_VALUES_MESSAGE = 'Kilómetros desde es mayor a kilómetros hasta';
const EMPTY_VALUES_MESSAGE = 'Debe ingresar un valor';

const KilometersRange = ({ min, max, onUpdateFilter, condition = '0' }) => {
  const classes = useStyles();

  const [kmMin, setkmMin] = useState(null);
  const [kmMax, setkmMax] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const optionsToShow = condition !== '0' ? kilometrosOptions.slice(1,kilometrosOptions.length) : kilometrosOptions;

  const checkValidRanges = (min,max) => {

    const maxLength = min?.toString().length || 0;
    const minLength = max?.toString().length || 0;

    if( maxLength === 0 && minLength === 0 ) {
      setError(true);
      setErrorMessage(EMPTY_VALUES_MESSAGE);
      return false;
    }
    if (parseInt(min) > parseInt(max)) {
      setError(true);
      setErrorMessage(INVALID_VALUES_MESSAGE);
      return false;
    }
    setErrorMessage('');
    setError(false);
    return true;
  };

  const updateRange = (event) => {
    event.preventDefault();
    if(checkValidRanges(kmMin,kmMax)){
      onUpdateFilter('kilometersRange', { kmMin, kmMax });
    }
  };

  const clearFilterRange = () => {
    setkmMin(null);
    setkmMax(null);
    setError(false);
    setErrorMessage('');
  };

  const updateFilterByRadio = (value) => onUpdateFilter('kilometersRange', JSON.parse(value));

  const handleOnChangeRadio = (event) => {
    clearFilterRange();
    setSelectedRadio(event.target.value);
    updateFilterByRadio(event.target.value);
  };

  const handleKmMin = (event) => {
    setSelectedRadio(null);
    setkmMin(event.target.value);
  };

  const handleKmMax = (event) => {
    setSelectedRadio(null);
    setkmMax(event.target.value);
  };

  useEffect(() => {
    if(!min && !max) {
      clearFilterRange();
      setSelectedRadio(null);
    }
  }, [min, max]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="left">
        <Box display="flex" flexDirection="row">
          <RadioButtonsGroup
            value={selectedRadio}
            onChange={handleOnChangeRadio}
            options={optionsToShow.map(km => ({
              label: km.label,
              value: JSON.stringify(km.value)
            }))}
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" paddingTop="3px">
          <Box display="flex" flexDirection="row">
            <Grid alignItems="center" className={classes.test}>
              <Grid className={classes.inputContainer}>
                <MaskedTextField
                  value={kmMin}
                  mask={mask}
                  onChange={handleKmMin}
                  variant="outlined"
                  size="small"
                  placeholder="Desde"
                  error={error}
                  InputProps={{
                    classes: {
                      root: classes.textInput,
                      adornedEnd: classes.inputAdornedEnd,
                    },
                  }}
                  className={classes.borderContainer}
                />
              </Grid>
            </Grid>
            <Grid alignItems="center">
              <Grid className={classes.inputContainer}>
                <MaskedTextField
                  value={kmMax}
                  mask={mask}
                  onChange={handleKmMax}
                  variant="outlined"
                  placeholder="Hasta"
                  size="small"
                  error={error}
                  InputProps={{
                    classes: {
                      root: classes.textInput,
                      adornedEnd: classes.inputAdornedEnd,
                    },
                  }}
                  className={classes.borderContainer}
                />
              </Grid>
            </Grid>
          </Box>

          <Box id="button" marginLeft="0.25rem">
            <IconButton
              variant='contained'
              type='submit'
              color="primary"
              onClick={(event) => updateRange(event)}
              className={classes.iconButtonContainer}>
              <ArrowForwardIcon className={classes.icon} />
            </IconButton>
          </Box>
        </Box>
        {errorMessage &&
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={isMobile ? classes.errorContainer : classes.errorContainerDesktop}>
            {errorMessage}
          </Box>
        }
      </Box>
    </>
  );
};

KilometersRange.propTypes = {
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  onUpdateFilter: PropTypes.func.isRequired
};

export default KilometersRange;
